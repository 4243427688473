import React from 'react'

import Layout from '../components/Layout'
import HomeHero from '../components/HomeHero/HomeHero'
import HomeWhat from '../components/HomeWhat'
import HomeWho from '../components/HomeWho'
import FavoriteRelationships from '../components/favoriteRelationships'
import HomeOurReach from '../components/HomeOurReach'
import HomeOurSolutions from '../components/HomeOurSolutions'
import HomeIntegration from '../components/HomeIntegration'

const IndexPage = () => (
    <Layout bodyClass="home">
        <HomeHero />
        <HomeWhat />
        <HomeWho />
        <FavoriteRelationships
            customers={[
                'Eightz',
                'BHGRE',
                'BHHSC',
                'BHHSG',
                'Carpenter',
                'HPW',
                'Cocoran',
                'Cresev',
                'CRMLS',
                'Elliman',
                'Edina',
                'Harry',
                'HomeServices',
                'Howard',
                'Intercoastal',
                'JohnLScott',
                'LongRealty',
                'NorthwestMls',
                'Parkco',
                'Parks',
                'RemaxResults',
                'ReeceNichols',
                'WilliamPitt',
            ]}
        />
        <HomeOurReach />
        <HomeOurSolutions />
        <HomeIntegration />
    </Layout>
)

export default IndexPage
