import React from 'react'

// https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react
const SlideShow = ({ slides }) => {
    const [index, setIndex] = React.useState(0)
    const timeoutRef = React.useRef(null)

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
    }

    React.useEffect(() => {
        resetTimeout()
        timeoutRef.current = setTimeout(
            () =>
                setIndex(prevIndex =>
                    prevIndex === slides.length - 1 ? 0 : prevIndex + 1
                ),
            slides[index].seconds * 1000
        )

        return () => {
            resetTimeout()
        }
    }, [index])

    return (
        <div className="slideshow">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {slides.map((slide, index) => (
                    <div className="slide" key={index}>
                        {slide.content}
                    </div>
                ))}
            </div>

            <div className="slideshowDots">
                {slides.map((_, idx) => (
                    <div
                        role="button"
                        tabIndex={0}
                        key={idx}
                        className={`slideshowDot${
                            index === idx ? ' active' : ''
                        }`}
                        onClick={() => {
                            setIndex(idx)
                        }}
                    ></div>
                ))}
            </div>
        </div>
    )
}

export default SlideShow
