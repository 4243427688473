import React from 'react'

export default function HomeWho({ data }) {
    const customers = [
        {
            title: 'Agents',
            link: '/agents',
            products: ['boost', 'spacio', 'connect'],
            quote:
                '“In a time when technology is radically changing how we communicate, HomeSpotter makes staying in front of home buyers and sellers easy.”',
            source: '— Matt Dejanovich, Real Estate One',
        },
        {
            title: 'Teams',
            link: '/teams',
            products: ['boost', 'spacio', 'connect'],
            quote:
                '“The most immediate benefit after digitizing open houses for our team was the ability to remarket to people through email more effectively.”',
            source: '— The Monica Diaz Team, eXp Realty',
        },
        {
            title: 'Brokerages & Franchisors',
            link: '/brokers',
            products: ['boost', 'spacio', 'connect'],
            quote:
                '“The HomeSpotter app was an absolute game-changer when we introduced it to our marketplace.”',
            source: '— Lake Slacum, VP Marketing, Intracoastal Realty',
        },
        {
            title: 'MLSs',
            link: '/mls/connect',
            products: ['connect'],
            quote:
                '“HomeSpotter is the perfect partner to develop a mobile solution to meet the needs of our members, which ultimately empowers them to exceed the expectations of their clients.”',
            source: '—  Art Carter, CRMLS',
        },
    ]

    const customerList = customers.map((customer, i) => {
        const productPills = customer.products.map((product, i) => {
            const className = `pill pill-${product}`
            return <span key={i} className={className} />
        })
        return (
            <div key={i} className="customer">
                <div className="customer-header">
                    <h3>
                        <a href={customer.link}>
                            <span className="customer-title">
                                {customer.title}
                            </span>
                            <span className="products">{productPills}</span>
                            <span className="arrow" />
                        </a>
                    </h3>
                </div>
                <p>{customer.quote}</p>
                <p className="source">{customer.source}</p>
            </div>
        )
    })

    return (
        <div className="home-who">
            <h2>Who is HomeSpotter for?</h2>
            <h4>
                We are the trusted partner for real estate businesses of all
                sizes and can scale to meet your needs.
            </h4>

            <div className="who-list">{customerList}</div>
        </div>
    )
}
