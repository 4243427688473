import React from 'react'

export default function UseCaseCard({
    className,
    verb,
    copy,
    ctaCopy,
    ctaLink,
    ctaColor,
}) {
    const classNames = `use-case-card ${className}`

    return (
        <div className={classNames}>
            <h1>{verb}</h1>
            <p>{copy}</p>
            <a style={{ color: ctaColor }} href={ctaLink}>
                {ctaCopy}
                <span style={{ paddingLeft: '8px' }}>➜</span>
            </a>
        </div>
    )
}
