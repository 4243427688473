import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const HomeSpotterHero = ({ data }) => {
    return (
        <div className="home-hero">
            <div className="copy-content">
                <h1>Real relationships = better business</h1>

                <p>
                    Humans aren’t leads or numbers. They’re people who want to
                    have a relationship with your company. We create, nurture
                    and strengthen the bond you have with your clients so that
                    your business thrives.
                </p>

                <p>No relationship is too big or too small.</p>
            </div>

            <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                className="hero-illustration"
                imgStyle={{
                    objectFit: 'contain',
                }}
            />
        </div>
    )
}

const HomeSpotterHeroRender = ({ ...props }) => {
    return (
        <StaticQuery
            query={graphql`
                {
                    file(relativePath: { eq: "homePageHero.png" }) {
                        childImageSharp {
                            gatsbyImageData(
                                width: 502
                                placeholder: NONE
                                layout: CONSTRAINED
                            )
                        }
                    }
                }
            `}
            render={data => <HomeSpotterHero data={data} {...props} />}
        />
    )
}

export default HomeSpotterHeroRender
