import React from 'react'
import { Link } from 'gatsby'

const HomeIntegration = () => {
    return (
        <div className="home-integration__container">
            <div className="integration__bg">
                <h1>Integration is in our DNA</h1>
                <div className="integration__dots">
                    <div className="dot rotate-78" />
                    <div className="dot rotate-134" />
                    <div className="dot rotate-178" />
                    <div className="dot rotate-129" />
                </div>
                <div className="integration__content">
                    We ensure you have access to what you want, when you want
                    it, in the places you want it. Avoid data lock in with our
                    50+ vendor integrations.
                </div>
                <Link to="/integrations">
                    Learn more about integrations
                    <span className="arrow">➜</span>
                </Link>
            </div>
        </div>
    )
}

export default HomeIntegration
