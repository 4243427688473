import React from 'react'
import ViewDemo from './ViewDemo'

export default function HomeOurReach({ data }) {
    const statList = [
        {
            stat: '20,000+',
            copy: 'Monthly listing ad campaigns',
            background: 'linear-gradient(101.93deg, #00C2C2 0%, #006BA7 100%)',
        },
        {
            stat: '450,000+',
            copy: 'Agents in our network',
            background: 'linear-gradient(121.73deg, #15C784 0%, #0E7242 100%)',
        },
        {
            stat: '1+ million',
            copy:
                '<a href="/boost/">Facebook real estate ad</a> impressions every day',
            background: 'linear-gradient(118.08deg, #F1864A 0%, #BF3825 100%)',
        },
        {
            stat: '300+',
            copy: 'MLS Integrations',
            background: 'linear-gradient(121.35deg, #15C784 0%, #0E7242 100%)',
        },
        {
            stat: 'Top 4',
            copy:
                'We serve all four of the largest brokerages in North America',
            background: 'linear-gradient(117.32deg, #F1864A 0%, #BF3825 100%)',
        },
        {
            stat: '95%',
            copy: 'Access to listings in North America',
            background: 'linear-gradient(102.75deg, #00C2C2 0%, #006BA7 100%)',
        },
    ]

    const statEls = statList.map(({ stat, copy, background }) => {
        return (
            <div key={stat} className="our-reach-card" style={{ background }}>
                <div className="stat-headline">{stat}</div>
                <div
                    className="stat-copy"
                    dangerouslySetInnerHTML={{ __html: copy }}
                />
            </div>
        )
    })

    return (
        <div className="home-our-reach">
            <div className="home-our-reach-content">
                <div className="home-our-reach-copy">
                    <h2>There's no mistaking our reach</h2>
                    <p>
                        We’re proud to be industry leaders in the real estate
                        market.
                    </p>
                    <ViewDemo>
                        <button className="button button-plain">
                            Learn More
                        </button>
                    </ViewDemo>
                </div>

                <div className="home-our-reach-card-list">{statEls}</div>
            </div>
        </div>
    )
}
