import React from 'react'

const BreakingNews = () => {
    return (
        <a className="news-item" href="https://blog.homespotter.com/?p=2896">
            <div className="pill pill-new" />
            <div className="news-copy">
                Breaking news! HomeSpotter joins Lone Wolf Technologies LEARN
                MORE HERE <span className="arrow right-arrow" />
            </div>
        </a>
    )
}

export default BreakingNews
