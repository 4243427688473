import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import MarketingRow from './MarketingRow'
import MarketingRowReversed from './MarketingRowReversed'

const HomeOurSolutions = ({ data }) => {
    const boostSolutionHeroImage = { src: data.boostSolutionHeroFile }
    const connectSolutionHeroImage = { src: data.connectSolutionHeroFile }
    const spacioSolutionHeroImage = { src: data.spacioSolutionHeroFile }

    return (
        <div className="home-our-solutions">
            <h3>Our solutions</h3>
            <MarketingRowReversed
                className="boost"
                hook="Boost for intelligent advertising"
                copy1="Brokerages, franchisors, teams, and agents trust Boost to automate their <a href='/boost/'>real estate ads</a> for listings, open houses, and more on Facebook, Instagram, and all across the web."
                image={boostSolutionHeroImage}
                ctaButton={{
                    class: 'boost',
                    text: 'Learn about Boost',
                    href: '/boost',
                }}
                quote={{
                    copy:
                        '“We just finished our first year with HomeSpotter and we could not be more pleased with the results.  Our listings are automatically boosted, saving us lots of admin time, and we are receiving a lot of local engagements, which will help us win more listings.”',
                    source: '— Andrew Ratner, VP, BHHS Gallo Realty',
                }}
            />
            <MarketingRow
                className="spacio"
                hook="Spacio for open houses"
                copy1="The industry’s leading end-to-end <a href='/spacio'>open house app</a> lead generation system. Spacio starts with sign in and provides you the lead management resources, tools, and integrations to convert guests into meaningful relationships."
                image={spacioSolutionHeroImage}
                ctaButton={{
                    class: 'spacio',
                    text: 'Learn about Spacio',
                    href: '/spacio',
                }}
                quote={{
                    copy:
                        '“My number one piece of advice for hosting a successful open house is to use Spacio. I can’t believe some agents still have visitors signing in on paper.”',
                    source:
                        "— Vincent Socci William Pitt Sotheby's International Realty",
                }}
            />
            <MarketingRowReversed
                className="connect"
                hook="Connect for client collaboration"
                copy1="Agents stay productive on the go with a mobile-first front end to the MLS and rich client collaboration platform centered around real-time property information and smart messaging, delivered to their clients through a brokerage and agent <a href='/connect'>branded MLS search app</a>."
                image={connectSolutionHeroImage}
                ctaButton={{
                    class: 'connect',
                    text: 'Learn about Connect',
                    href: '/connect',
                }}
                quote={{
                    copy:
                        '“The HomeSpotter App has been a huge asset for our clients and has made communication in real time possible.”',
                    source:
                        '— Todd Harrington - Exit Lakes Realty Premier Agent',
                }}
            />
        </div>
    )
}

const HomeOurSolutionsRender = props => (
    <StaticQuery
        query={graphql`
            {
                boostSolutionHeroFile: file(
                    relativePath: { eq: "boostSolutionHero.png" }
                ) {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 502
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                spacioSolutionHeroFile: file(
                    relativePath: { eq: "spacioSolutionHero.png" }
                ) {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 502
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                connectSolutionHeroFile: file(
                    relativePath: { eq: "connectSolutionHero.png" }
                ) {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 502
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={data => <HomeOurSolutions data={data} {...props} />}
    />
)

export default HomeOurSolutionsRender
