import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

export default function MarketingRow(props) {
    const {
        className,
        productHint,
        hook,
        followUp,
        copy1,
        image,
        ctaButton,
        quote,
        reverse,
        center,
        backgroundImage,
        disclaimer,
    } = props

    let ctaClass =
        (ctaButton && ctaButton.class) ||
        (productHint && productHint.toLowerCase())

    const ctaButtonEl = ctaButton ? (
        <a href={ctaButton.href} className={`button button-plain ${ctaClass}`}>
            {ctaButton.text}
        </a>
    ) : null

    let followUpEl = null
    if (followUp) {
        followUpEl = (
            <div
                className="follow-up"
                dangerouslySetInnerHTML={{__html: followUp}}
            />
        )
    }

    let disclaimerEl = null
    if (disclaimer) {
        disclaimerEl = <p className="disclaimer">{disclaimer}</p>
    }

    let quoteEl = null
    if (quote) {
        quoteEl = (
            <div className="marketing-row-quote">
                <p className="quote-copy">{quote.copy}</p>

                <p className="quote-source">{quote.source}</p>
            </div>
        )
    }

    let backgroundEl = null
    if (backgroundImage) {
        backgroundEl = (
            <div className={'bg bg-container'}>
                <div className={`bg ${backgroundImage}`}/>
            </div>
        )
    }

    return (
        <div id={hook.toLowerCase().replace(/\s+/g, '-')}>
            <div className="col-12 position-relative">
                {backgroundEl}
                <div
                    className={`solution-row marketing-row ${
                        reverse ? 'reverse' : ''
                    } ${center ? 'center' : ''} ${className ? className : ''}`}
                >
                    <div className="marketing-row-image-container">
                        {image && <GatsbyImage image={image.src.childImageSharp.gatsbyImageData} />}
                    </div>
                    <div className="marketing-row-content">
                        <div
                            className={`product-hint ${productHint &&
                            productHint.toLowerCase()}`}
                        >
                            {productHint}
                        </div>
                        <h2 className={productHint && productHint.toLowerCase()}>
                            {hook}
                        </h2>
                        {followUpEl}
                        <p
                            className="copy1"
                            dangerouslySetInnerHTML={{__html: copy1}}
                        />

                        {disclaimerEl}
                        {quoteEl}

                        {ctaButtonEl}
                    </div>
                </div>
            </div>
        </div>
    );
}
