import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ConnectAndroidStoreButton from '../../images/connectAndroidStoreButton.svg'
import ConnectAppleStoreButton from '../../images/connectAppleStoreButton.svg'

const ConnectHero = ({ data }) => {
    return (
        <div className="home-hero">
            <div className="copy-content">
                <h1>Download the HomeSpotter App</h1>

                <p>
                    HomeSpotter is a beautiful, mobile-first home search app
                    with built-in chat that makes collaboration fast, easy and
                    fun.
                </p>
                <div className="connect-ctas">
                    <a
                        className="connect-cta"
                        href="https://apps.apple.com/us/app/homespotter-real-estate/id958764301"
                    >
                        <img
                            alt="Apple Store Connect link"
                            src={ConnectAppleStoreButton}
                        />
                    </a>
                    <a
                        className="connect-cta"
                        href="https://play.google.com/store/apps/details?id=com.mobilerealtyapps.homespotter&hl=en_US&gl=US"
                    >
                        <img
                            alt="Android Store Connect link"
                            src={ConnectAndroidStoreButton}
                        />
                    </a>
                </div>
            </div>

            <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                className="hero-illustration"
                imgStyle={{
                    objectFit: 'contain',
                }}
            />
        </div>
    )
}

const ConnectHeroRender = ({ ...props }) => {
    return (
        <StaticQuery
            query={graphql`
                {
                    file(relativePath: { eq: "connectHero.png" }) {
                        childImageSharp {
                            gatsbyImageData(
                                width: 502
                                placeholder: NONE
                                layout: CONSTRAINED
                            )
                        }
                    }
                }
            `}
            render={data => <ConnectHero data={data} {...props} />}
        />
    )
}

export default ConnectHeroRender
