import React from 'react'
import UseCaseCard from '../components/UseCaseCard'

export default function HomeWhat({ data }) {
    return (
        <div className="home-what">
            <h2>What can you do with HomeSpotter?</h2>

            <div>
                <div className="home-what-product-cards">
                    <div className="card-set boost-cards">
                        <div className="home-what-header boost">
                            Boost for intelligent advertising
                        </div>

                        <UseCaseCard
                            className="boost-attract"
                            verb="Attract"
                            copy="Target your best prospects on Facebook, Instagram, and all across the web."
                            ctaCopy="Learn about Boost"
                            ctaLink="/boost"
                            ctaColor="#00A567"
                        />

                        <UseCaseCard
                            className="boost-automate"
                            verb="Automate"
                            copy="Auto-create new ads at every step of the listing lifecycle - new, open house, price reduced, and sold."
                            ctaCopy="Learn about Boost"
                            ctaLink="/boost"
                            ctaColor="#00A567"
                        />
                    </div>

                    <div className="card-set spacio-cards">
                        <div className="home-what-header spacio">
                            Spacio for open houses
                        </div>

                        <UseCaseCard
                            className="spacio-capture"
                            verb="Capture"
                            copy="Generate higher quality leads and close more deals."
                            ctaCopy="Learn about Spacio"
                            ctaLink="/spacio"
                            ctaColor="#DA843C"
                        />

                        <UseCaseCard
                            className="spacio-nurture"
                            verb="Nurture"
                            copy="Build meaningful relationships with open house visitors."
                            ctaCopy="Learn about Spacio"
                            ctaLink="/spacio"
                            ctaColor="#DA843C"
                        />
                    </div>

                    <div className="card-set connect-cards">
                        <div className="home-what-header connect">
                            Connect for client collaboration
                        </div>

                        <UseCaseCard
                            className="connect-collaborate"
                            verb="Search"
                            copy="Recommend the perfect MLS listings to your clients with your own branded search app."
                            ctaCopy="Learn about Connect"
                            ctaLink="/connect"
                            ctaColor="#F15F4A"
                        />

                        <UseCaseCard
                            className="connect-integrate"
                            verb="Empower"
                            copy="Do more of your job on-the-go without ever needing to pull out your laptop."
                            ctaCopy="Learn about Connect"
                            ctaLink="/connect"
                            ctaColor="#F15F4A"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
