import React from 'react'
import BoostHero from './BoostHero'
import ConnectHero from './ConnectHero'
import HomeSpotterHero from './HomeSpotterHero'
import Slideshow from './Slideshow'
import BreakingNews from './BreakingNews'

const slides = [
    { content: <BoostHero />, seconds: 7 },
    { content: <ConnectHero />, seconds: 4 },
    { content: <HomeSpotterHero />, seconds: 4 },
]

const SlideshowRender = () => (
    <div>
        {BreakingNews()}
        <Slideshow slides={slides} />
    </div>
)

export default SlideshowRender
