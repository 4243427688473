import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const BoostHero = ({ data }) => {
    return (
        <div className="home-hero">
            <div className="copy-content">
                <h1>Advertise with Boost</h1>

                <p>
                    Boost helps automate your digital advertising strategy, so
                    you can enjoy more face time with clients. With Boost, you
                    can customize each ad, track engagement and reach, build
                    your personal brand, and so much more.
                </p>

                <a className="agents-boost-button" href="/agents/boost">
                    Start Advertising
                </a>
            </div>

            <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                className="hero-illustration"
                imgStyle={{
                    objectFit: 'contain',
                }}
            />
        </div>
    )
}

const BoostHeroRender = ({ ...props }) => {
    return (
        <StaticQuery
            query={graphql`
                {
                    file(relativePath: { eq: "boostHero.png" }) {
                        childImageSharp {
                            gatsbyImageData(
                                width: 502
                                placeholder: NONE
                                layout: CONSTRAINED
                            )
                        }
                    }
                }
            `}
            render={data => <BoostHero data={data} {...props} />}
        />
    )
}

export default BoostHeroRender
